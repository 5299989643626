import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import config from '@/config';
import { HttpClientProps } from '@/utils/httpClient/types';

export type TQueryConfig = {
  manuallyHandlingErrorMsg?: boolean;
};

const baseURL = config.apiUrl;

const httpClient = <ReturnType>(config: HttpClientProps & TQueryConfig) => {
  const { url, method, customHeader, params, data, manuallyHandlingErrorMsg } = config;
  const accessToken = localStorage.accessToken || sessionStorage.accessToken;

  const axiosConfig: AxiosRequestConfig & TQueryConfig = {
    baseURL,
    url,
    method,
    ...(customHeader ?? {
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
    }),
    ...(params && { params }),
    ...(data && { data }),
    ...(manuallyHandlingErrorMsg && { manuallyHandlingErrorMsg }),
  };

  return new Promise<ReturnType>((resolve, reject) =>
    axios(axiosConfig)
      .then(response => resolve(response.data.data))
      .catch((e: AxiosError) => reject(e))
  );
};

export default httpClient;
