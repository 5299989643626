import React, { Component, ReactNode } from 'react';
import { Button } from 'antd';

type Props = {
  children?: ReactNode;
};

type State = {
  hasError: boolean;
  errorInfo: Error | null;
};

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorInfo: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, errorInfo: error };
  }

  public render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
    return (
      <div className="flex h-screen w-screen flex-col items-center justify-center">
        <h3 className="text-2xl font-semibold mb-1">Có lỗi xảy ra!</h3>
        <p className="text-base mb-6">Vui lòng thử tải lại hoặc quay về trang trước</p>
        <div className="jusitfy-center flex items-center gap-x-4">
          <Button type="primary" size="large" onClick={() => window.location.reload()}>
            Tải lại trang
          </Button>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary;
