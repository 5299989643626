import { makeAutoObservable } from 'mobx';
import { TDestination } from '@features/hotel/types';

export class DestinationStore {
  destinations: TDestination[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setDestinations = (destinations: TDestination[]) => {
    this.destinations = destinations;
  };
}

const destinationStore = new DestinationStore();

export default destinationStore;
