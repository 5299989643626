import { CARD_TYPE } from '@/utils/regex';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DAY_FORMAT = 'dd';
export const TIME_FORMAT = 'HH:mm';
export const DATE_AND_MONTH_FORMAT = 'DD/MM';
export const FULL_DATE_TIME_FORMAT = 'HH:mm dddd, DD/MM/YYYY';
export const DEFAULT_LOCALE = 'vi-VN';

export enum APP_TYPE {
  BOOKING = 'booking',
  PARTNER = 'partners',
  REPORT = 'reports',
  BUSINESS = 'business',
}

export const AppName = {
  [APP_TYPE.BOOKING]: 'Đơn hàng',
  [APP_TYPE.PARTNER]: 'Đối tác',
  [APP_TYPE.REPORT]: 'Báo cáo',
  [APP_TYPE.BUSINESS]: 'Nghiệp vụ',
} as const;

export enum GENDER {
  FEMALE,
  MALE,
}

// TODO: https://docs.google.com/spreadsheets/d/15oP07ciMcG7AcZScY5K4u3cw4-IXXyYk9kvKMXs0SGc/edit#gid=0
export enum PERMISSIONS {
  // Booking
  BOOKING = 'booking',
  BOOKING_CREATE = 'booking_create',
  BOOKING_CREATE_AIR = 'booking_create_air',
  BOOKING_CREATE_HOTEL = 'booking_create_hotel',
  BOOKING_CREATE_TICKET = 'booking_create_ticket',
  BOOKING_CREATE_INSURANCE = 'booking_create_insurance',
  BOOKING_ADD_AIR = 'booking_add_air',
  BOOKING_ISSUE = 'booking_issue',
  BOOKING_REPORT = 'booking_report',
  BOOKING_LIST = 'booking_list',
  BOOKING_LIST_AIR = 'booking_list_air',
  BOOKING_LIST_HOTEL = 'booking_list_hotel',
  BOOKING_LIST_TICKET = 'booking_list_ticket',
  BOOKING_LIST_INSURANCE = 'booking_list_insurance',
  BOOKING_WEB_CREATE = 'booking_web_create',
  BOOKING_WEB_EDIT_FEE = 'booking_web_edit_fee',
  BOOKING_SERVICE_FEE_INIT = 'booking_service_fee_init',
  BOOKING_SERVICE_FEE_REFRESH = 'booking_service_fee_refresh',
  BOOKING_USER = 'booking_user',
  BOOKING_USER_CREATE = 'booking_user_create',
  BOOKING_USER_UPDATE = 'booking_user_update',
  BOOKING_WEB = 'booking_web',
  BOOKING_WEB_UPDATE = 'booking_web_update',
  BOOKING_WEB_SETTING = 'booking_web_setting',
  BOOKING_WEB_SETTING_UPDATE = 'booking_web_setting_update',
  BOOKING_SERVICE_FEE = 'booking_service_fee',
  BOOKING_SERVICE_FEE_CREATE = 'booking_service_fee_create',
  BOOKING_SERVICE_FEE_UPDATE = 'booking_service_fee_update',
  BOOKING_SERVICE_FEE_DELETE = 'booking_service_fee_delete',
  BOOKING_PARTNER_SETTING = 'booking_partner_setting',
  BOOKING_PARTNER_UPDATE = 'booking_partner_update',
  BOOKING_PARTNER_SETTING_SIGNIN = 'booking_partner_setting_signin',
  BOOKING_PARTNER_UPDATE_SIGNIN = 'booking_partner_update_signin',
  // Partner
  PARTNER = 'partner',
  PARTNER_LIST = 'partner_list',
  PARTNER_CREATE = 'partner_create',
  PARTNER_UPDATE = 'partner_update',
  PARTNER_USER_LIST = 'partner_user_list',
  PARTNER_USER_CREATE = 'partner_user_create',
  PARTNER_USER_UPDATE = 'partner_user_update',
  PARTNER_ISSUE_FEE = 'partner_issue_fee',
  PARTNER_ISSUE_FEE_CREATE = 'partner_issue_fee_create',
  PARTNER_ISSUE_FEE_UPDATE = 'partner_issue_fee_update',
  PARTNER_ISSUE_FEE_DELETE = 'partner_issue_fee_delete',
  PARTNER_ISSUE_FEE_REFRESH = 'partner_issue_fee_refresh',
  PARTNER_ISSUE_FEE_INIT = 'partner_issue_fee_init',
  PARTNER_DEPOSIT = 'partner_deposit',
  PARTNER_DEPOSIT_UPDATE = 'partner_deposit_update',
  PARTNER_DEPOSIT_TRANSACTION = 'partner_deposit_transaction',
  PARTNER_DEPOSIT_TRANSACTION_CREATE = 'partner_deposit_transaction_create',
  PARTNER_INSURANCE_ADMINFEE = 'partner_insurance_adminfee',
  PARTNER_INSURANCE_ADMINFEE_CREATE = 'partner_insurance_adminfee_create',
  PARTNER_INSURANCE_ADMINFEE_UPDATE = 'partner_insurance_adminfee_update',
  PARTNER_INSURANCE_ADMINFEE_DELETE = 'partner_insurance_adminfee_delete',
  PARTNER_INSURANCE_ADMINFEE_REFRESH = 'partner_insurance_adminfee_refresh',
  PARTNER_INSURANCE_COMMISSION = 'partner_insurance_commission',
  // Report
  REPORT = 'report',
  REPORT_LIST_ISSUED = 'report_list_issued',
  REPORT_LIST_VOID = 'report_list_void',
  // Business Ops
  BUSINESS_OPERATION = 'business_operation',
  BUSINESS_OPERATION_CREATE = 'business_operation_create',
  BUSINESS_OPERATION_CREATE_AIR = 'business_operation_create_air',
  BUSINESS_OPERATION_CREATE_INSURANCE = 'business_operation_create_insurance',
  BUSINESS_OPERATION_CREATE_TICKET = 'business_operation_create_ticket',
  BUSINESS_OPERATION_CREATE_HOTEL = 'business_operation_create_hotel',
  BUSINESS_OPERATION_BOOKING_LIST = 'business_operation_booking_list',
  BUSINESS_OPERATION_BOOKING_MODIFY = 'business_operation_booking_modify',
  BUSINESS_OPERATION_BOOKING_ISSUE = 'business_operation_booking_issue',
  BUSINESS_OPERATION_AIRLINE_VOID = 'business_operation_airline_void',
  BUSINESS_OPERATION_LIST_ISSUED = 'business_operation_list_issued',
  BUSINESS_OPERATION_LIST_VOID = 'business_operation_list_void',
}

export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ROLES {
  ADMIN = 'ADMIN',
  ACCOUNTING = 'ACCOUNTING',
  ACCOUNTING_SUB = 'ACCOUNTING_SUB',
  ISSUE = 'ISSUE',
  BOOKING = 'BOOKING',
}

export const RoleOptions = [
  { label: 'Quản trị', value: ROLES.ADMIN },
  { label: 'Kế toán chính', value: ROLES.ACCOUNTING },
  { label: 'Kế toán viên', value: ROLES.ACCOUNTING_SUB },
  { label: 'Đặt giữ chỗ', value: ROLES.BOOKING },
  { label: 'Xuất vé', value: ROLES.ISSUE },
] as const;

export enum PAYMENT_METHOD {
  PAY_LATER = 'PAY_LATER',
  PAY_NOW = 'PAY_NOW',
}

export enum BOOKING_STATUS {
  BOOK_SUCCESS = 'BOOK_SUCCESS', //Đặt giữ chỗ
  BOOK_FAILED = 'BOOK_FAILED', //Đặt lỗi
  ISSUE_WAITING = 'ISSUE_WAITING', //Chờ xuất
  ISSUE_REQUEST = 'ISSUE_REQUEST', //Yêu cầu xuất
  ISSUE_SUCCESS = 'ISSUE_SUCCESS', //Đã xuất
  ISSUE_FAILED = 'ISSUE_FAILED', //Xuất lỗi
  CANCELED = 'CANCELED', //Đã hủy
  //TODO: Tạm thời ẩn check-in
  //CHECKIN = 'CHECKIN', //Đã check-in
  VOID_SUCCESS = 'VOID_SUCCESS', //Đã void
  VOID_FAILED = 'VOID_FAILED', //Void lỗi
}

export enum REPORT_TYPE {
  AIRLINE = 'AIRLINE',
  TICKET = 'TICKET',
  HOTEL = 'HOTEL',
  INSURANCE = 'INSURANCE',
}

export const ReportTypeOptions = [
  { label: 'Vé máy bay', value: REPORT_TYPE.AIRLINE },
  { label: 'Vé vui chơi', value: REPORT_TYPE.TICKET },
  { label: 'Khách sạn', value: REPORT_TYPE.HOTEL },
  { label: 'Bảo hiểm', value: REPORT_TYPE.INSURANCE },
] as const;

export const BookingStatusOptions = [
  { [BOOKING_STATUS.BOOK_SUCCESS]: 'Đặt giữ chỗ' },
  { [BOOKING_STATUS.BOOK_FAILED]: 'Đặt lỗi' },
  { [BOOKING_STATUS.ISSUE_WAITING]: 'Chờ xuất' },
  { [BOOKING_STATUS.ISSUE_REQUEST]: 'Yêu cầu xuất' },
  { [BOOKING_STATUS.ISSUE_SUCCESS]: 'Đã xuất' },
  { [BOOKING_STATUS.ISSUE_FAILED]: 'Xuất lỗi' },
  { [BOOKING_STATUS.CANCELED]: 'Đã huỷ' },
  //{ [BOOKING_STATUS.CHECKIN]: 'Đã Check-in' },
  { [BOOKING_STATUS.VOID_SUCCESS]: 'Đã void' },
  { [BOOKING_STATUS.VOID_FAILED]: 'Void lỗi' },
] as const;

export enum PARTNER_AIR_TICKET_SERVICE_MANAGEMENT_ACTIONS {
  INIT_DEFAULT = 'INIT_DEFAULT',
  REFRESH = 'REFRESH',
}

export enum PARTNER_UPDATE_TYPE {
  SELF = 'SELF',
  CHILDREN = 'CHILDREN',
}

export enum DEPOSIT_FILTER_VALUE {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum TRANSACTION_TYPE {
  AUTOMATIC = 'AUTOMATIC',
  MODIFY = 'MODIFY',
}

export const AcceptedBookingPaymentStatus = [
  BOOKING_STATUS.BOOK_SUCCESS,
  BOOKING_STATUS.ISSUE_SUCCESS,
  BOOKING_STATUS.ISSUE_REQUEST,
  BOOKING_STATUS.ISSUE_FAILED,
] as BOOKING_STATUS[];

export const LanguageOptions = [
  { value: 'VN', label: 'Tiếng Việt' },
  { value: 'EN', label: 'Tiếng Anh' },
];

export const IconCreditCardType: Record<'type' | 'src', string>[] = [
  {
    type: CARD_TYPE.VISA,
    src: `${process.env.PUBLIC_URL}/assets/svgs/creditCard/visa.svg`,
  },
  {
    type: CARD_TYPE.MASTER_CARD,
    src: `${process.env.PUBLIC_URL}/assets/svgs/creditCard/mastercard.svg`,
  },
  {
    type: CARD_TYPE.JCB,
    src: `${process.env.PUBLIC_URL}/assets/svgs/creditCard/jcb.svg`,
  },
  {
    type: CARD_TYPE.AMERICAN_EXPRESS,
    src: `${process.env.PUBLIC_URL}/assets/svgs/creditCard/amex.svg`,
  },
  {
    type: CARD_TYPE.DISCOVER,
    src: `${process.env.PUBLIC_URL}/assets/svgs/creditCard/discover.svg`,
  },
  {
    type: CARD_TYPE.DINERS_CLUB,
    src: `${process.env.PUBLIC_URL}/assets/svgs/creditCard/diners.svg`,
  },
  {
    type: CARD_TYPE.UNION_PAY,
    src: `${process.env.PUBLIC_URL}/assets/svgs/creditCard/unionpay.svg`,
  },
  {
    type: CARD_TYPE.ELO,
    src: `${process.env.PUBLIC_URL}/assets/svgs/creditCard/elo.svg`,
  },
];

export const Gender = [
  { value: GENDER.MALE, label: 'Nam' },
  { value: GENDER.FEMALE, label: 'Nữ' },
];

export enum PAYMENT_OPTION {
  DEPOSIT = 'DEPOSIT',
  CREDIT_CARD = 'CREDIT_CARD',
}
