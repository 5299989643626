import React, { FunctionComponent, memo, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import config from 'config';

type Props = {
  title?: string;
  description?: string;
  keywords?: string;
  type?: string;
  name?: string;
};

const AppHelmet: FunctionComponent<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, description = '', keywords, type = '', name = '' } = props;
  const SEOKeywords = useMemo(() => keywords || 'Eco, manager', [keywords]);
  const FacebookDomainVerificationCode = useMemo(() => '36wh0henmjj0mt74lo8ynw60cab51c', []);
  const pageTitle = title ? config.appName + ' - ' + title : config.appName;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="facebook-domain-verification" content={FacebookDomainVerificationCode} />
      <meta name="description" content={description} />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="robots" content="index, follow" />
      <meta key="googlebot" name="googlebot" content="index,follow" />
      <meta name="google" content="notranslate" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="keywords" content={SEOKeywords} />
      {/*/!*Facebook*!/*/}
      {/*<meta property="og:locale" content="vi_VN" />*/}
      {/*<meta property="og:site_name" content="787.VN" />*/}
      {/*<meta property="og:type" content={type} />*/}
      {/*<meta property="og:title" content={title} />*/}
      {/*<meta property="og:description" content={description} />*/}
      {/*<meta property="og:url" content="https://787.vn/" />*/}
      {/*<meta property="og:image" content="https://787.vn/images/logo/Logo.png" />*/}
      {/*/!*Twitter*!/*/}
      {/*<meta property="twitter:creator" content={name} />*/}
      {/*<meta property="twitter:card" content={type} />*/}
      {/*<meta property="twitter:title" content={title} />*/}
      {/*<meta property="twitter:description" content={description} />*/}
      {/*<meta property="twitter:url" content="https://787.vn/" />*/}
      {/*/!*Favicon*!/*/}
      {/*<link rel="shortcut icon" href="https://787.vn/images/logo/logo-title.png" type="image/png" />*/}
      {/*<link rel="canonical" href="https://787.vn" />*/}
    </Helmet>
  );
};

export default memo(AppHelmet);
