import { makeAutoObservable } from 'mobx';
import { TPartnerServices } from '@features/webBooking/types';

export class ServiceDataStore {
  partnerServices: TPartnerServices[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setPartnerServices = (partnerServices: TPartnerServices[]) => {
    this.partnerServices = partnerServices;
  };
}

const serviceDataStore = new ServiceDataStore();
export default serviceDataStore;
