import { makeAutoObservable } from 'mobx';
import { TGetFilterResponse, TGetHotelResponse } from '@features/hotel/types';

export class HotelStore {
  showFilter = false;
  filterData: TGetFilterResponse = {} as TGetFilterResponse;
  hotelDetail: TGetHotelResponse = {} as TGetHotelResponse;

  constructor() {
    makeAutoObservable(this);
  }

  setShowFilter = (show: boolean) => {
    this.showFilter = show;
  };

  setFilterData = (filterData: TGetFilterResponse) => {
    this.filterData = filterData;
  };

  setHotelDetail = (detail: TGetHotelResponse) => {
    this.hotelDetail = detail;
  };
}

const hotelStore = new HotelStore();

export default hotelStore;
