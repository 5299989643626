import { makeAutoObservable } from 'mobx';
import {
  TFlightFilter,
  TItineraryData,
  TSearchFlightResponse,
  TSelectedFlight,
} from '@features/webBooking/types';
import { SORT_BY, SORT_TYPE } from '@features/webBooking/constants';

export class FlightDataStore {
  flightData: TSearchFlightResponse = {} as TSearchFlightResponse;
  flightFilter: TFlightFilter = {
    sortBy: SORT_BY.PRICE,
    sortType: SORT_TYPE.ASCENDING,
  } as TFlightFilter;
  itineraryData: TItineraryData = {} as TItineraryData;
  showPriceIncludesTax = true;
  showFilter = false;
  flightSelectedData: TSelectedFlight = {} as TSelectedFlight;
  baggagePrice = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get isDepartureFlightSelected() {
    return !!Object.keys(this.itineraryData.departure || {}).length;
  }

  get isReturnFlightSelected() {
    return !!Object.keys(this.itineraryData.return || {}).length;
  }

  setFlightData = (flightData: TSearchFlightResponse) => {
    this.flightData = flightData;
  };

  setFlightFilter = (flightFilter: TFlightFilter) => {
    this.flightFilter = flightFilter;
  };

  setItineraryData = (itineraryData: TItineraryData) => {
    this.itineraryData = itineraryData;
  };

  setShowPriceIncludesTax = (show: boolean) => {
    this.showPriceIncludesTax = show;
  };

  setShowFilter = (show: boolean) => {
    this.showFilter = show;
  };

  handleResetFlightDataStore = () => {
    this.flightFilter = {
      sortBy: SORT_BY.PRICE,
      sortType: SORT_TYPE.ASCENDING,
    } as TFlightFilter;
    this.setItineraryData({} as TItineraryData);
  };

  setFlightSelectedData = (flightSelectedData: TSelectedFlight) => {
    this.flightSelectedData = flightSelectedData;
  };

  setBaggagePrice = (baggagePrice: number) => {
    this.baggagePrice = baggagePrice;
  };
}

const flightDataStore = new FlightDataStore();

export default flightDataStore;
