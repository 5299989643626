import { makeAutoObservable } from 'mobx';
import type { TAirport } from '@features/airport/types';

export class AirportDataStore {
  constructor() {
    makeAutoObservable(this);
  }

  airportData: TAirport[] = [];
  setAirportData = (airportData: TAirport[]) => {
    this.airportData = airportData;
  };
}

const airportDataStore = new AirportDataStore();

export default airportDataStore;
