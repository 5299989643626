import { makeAutoObservable } from 'mobx';
import { TPaginationResponse } from '@features/partners/types';
import { TFetchPartnerDeposit, TPartnerDepositTransaction } from '@features/reports/types';

export class PartnerStore {
  partnerDeposits: TPaginationResponse<TFetchPartnerDeposit[]> | undefined = undefined;
  partnerDepositTransaction: TPartnerDepositTransaction | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setPartnerDeposits = (partnerDeposits: TPaginationResponse<TFetchPartnerDeposit[]>) => {
    this.partnerDeposits = partnerDeposits;
  };
  setPartnerDepositTransaction = (data: TPartnerDepositTransaction) => {
    this.partnerDepositTransaction = data;
  };
}

const partnerStore = new PartnerStore();
export default partnerStore;
