import { makeAutoObservable } from 'mobx';
import { TSelectedPartnerAndService } from '@features/partners/types';

export class SelectedPartnerAndServiceStore {
  selectedPartnerAndService = {} as TSelectedPartnerAndService;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedPartnerAndService = (selectedPartnerAndService: TSelectedPartnerAndService) => {
    this.selectedPartnerAndService = selectedPartnerAndService;
  };

  resetSelectedPartnerAndService = () => {
    this.selectedPartnerAndService = {} as TSelectedPartnerAndService;
  };
}

const selectedPartnerAndServiceStore = new SelectedPartnerAndServiceStore();

export default selectedPartnerAndServiceStore;
