import React, { FC } from 'react';
import AppHelmet from '@components/Helmet';

const NotFoundPage: FC = () => {
  return (
    <main className="p-15 flex items-center justify-center w-full h-full">
      <AppHelmet title="Không tìm thấy trang" />
      <h1 className="text-9xl">404</h1>
    </main>
  );
};

export default NotFoundPage;
