import { TSortOption } from '@features/webBooking/types';

export enum BOOKING_TYPE {
  AIRLINE_TICKETS = 'airline-tickets',
  HOTEL = 'hotel',
  AMUSEMENT_PARK = 'amusement-park',
  INSURANCE = 'insurance',
  VISA = 'visa',
  COMBO = 'combo',
  TOUR = 'tour',
}

export enum AIR_TICKET_BOOKING_STATUS {
  RESERVED = 'reserved',
  ISSUED = 'issued',
  FAILED = 'failed',
}

export enum AIR_TICKET_BOOKING_TYPE {
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
}

export enum SORT_BY {
  PRICE = 'PRICE',
  START_DATE = 'START_DATE',
}

export enum SORT_TYPE {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum ITINERARY {
  DEPARTURE,
  RETURN,
}

export const sortOptions: TSortOption[] = [
  {
    value: SORT_BY.PRICE,
    label: 'Vé rẻ nhất',
    define: {
      sortBy: SORT_BY.PRICE,
      sortType: SORT_TYPE.ASCENDING,
    },
  },
  {
    value: SORT_BY.START_DATE,
    label: 'Giờ bay sớm nhất',
    define: {
      sortBy: SORT_BY.START_DATE,
      sortType: SORT_TYPE.ASCENDING,
    },
  },
];

export enum RULE_TYPE {
  NOTE = 'NOTE',
  INFO = 'INFO',
  HAND_BAGGAGE = 'HAND_BAGGAGE',
  CHECKED_BAGGAGE = 'CHECKED_BAGGAGE',
  REFUND = 'REFUND',
  CHANGE = 'CHANGE',
  NO_SHOW = 'NO_SHOW',
  GO_SHOW = 'GO_SHOW',
}

export const CalendarNavWeekDayDifferences = [-3, -2, -1, 0, 1, 2, 3];

export enum ALERT_TYPE {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export const PassengerType = {
  adult: 'ADT',
  children: 'CHD',
  infant: 'INF',
} as const;

export enum GET_BOOKING_TYPE {
  AIRLINE = 'AIRLINE',
  TICKET = 'TICKET',
  HOTEL = 'HOTEL',
  INSURANCE = 'INSURANCE',
}

export enum FLIGHT_SESSION {
  DEPARTURE = 'departureFlightSession',
  RETURN = 'returnFlightSession',
}

export enum OCCUPANCY_TYPE {
  ADULT,
  CHILDREN,
}
